import Wrapper from './Wrapper.vue';

import GetWhitePaper from  './GetWhitePaper/index.vue';
import BecomePartner from  './BecomePartner.vue';
import ContactUs from  './ContactUs.vue';
import RequestDemo from  './RequestDemo.vue';
import RequestDemoVSA from  './RequestDemoVSA.vue';
import RequestDemoShort from  './RequestDemoShort.vue';
import RequestDemoVeeam from  './RequestDemoVeeam.vue';
import RequestDemoZeroBounce from './RequestDemoZeroBounce.vue';

export default {
  Wrapper,

  GetWhitePaper,
  BecomePartner,
  ContactUs,
  RequestDemo,
  RequestDemoVSA,
  RequestDemoShort,
  RequestDemoVeeam,
  RequestDemoZeroBounce
}
