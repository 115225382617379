<template>
  <form
    data-component-name="BecomePartner"
    autocomplete="off"
    @input.passive="setFormFillingStart"
    @submit="submitHandler"
    data-id="becomePartnerForm"
  >
    <AppInput
      v-model="firstname"
      type="text"
      :label="$t('First_Name')"
      :error="errors.firstname"
      name="firstname"
      required
    />

    <AppInput
      v-model="lastname"
      type="text"
      :label="$t('Last_Name')"
      :error="errors.lastname"
      name="lastname"
      required
    />

    <AppInput
      v-model="email"
      type="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      name="email"
      required
    />

    <AppInput
      v-model="company"
      type="text"
      :label="$t('Company')"
      :error="errors.company"
      name="company"
      required
    />

    <AppInput
      v-model="title"
      type="text"
      :label="$t('Job_Title')"
      :error="errors.title"
      name="jobTitle"
      required
    />

    <div class="radio">
      <strong>{{ $t('are_you_veeam_partner') }}</strong>

      <ul>
        <li
          v-for="option of RADIO_OPTIONS"
          :key="option"
        >
          <AppRadio
            v-model="veeam_partner"
            :value="option"
            :id="`veeamPartner-${option}`"
            name="role"
          />

          <label :for="`veeamPartner-${option}`">{{ option.toLowerCase() }}</label>
        </li>
      </ul>

      <span v-if="errors.veeam_partner" class="error" name="error">
        {{ errors.veeam_partner }}
      </span>
    </div>

    <AppDropdown
      v-model="country"
      :options="activeCountries"
      option-attribute="label"
      :label="$t('Country')"
      :placeholder="$t('Select_your_country')"
      required
      searchable
      name="country"
      :searchable-placeholder="$t('Search_by_country_name')"
      :search-attributes="['label', 'isoCode']"
      :error="errors.country"
    />

    <AppDropdown
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      option-attribute="label"
      label="State"
      :placeholder="$t('Select_your_state')"
      required
      searchable
      name="state"
      :searchable-placeholder="$t('Search_by_state_name')"
      :search-attributes="['label', 'isoCode']"
      :error="errors.state"
    />

    <PhoneInput
      v-model="phone"
      :error="errors.phone"
      :country-ISO="country.isoCode"
      name="phone"
      required
      ref="phoneInputRef"
    />

    <div class="terms">
      <GDPRForUSA v-if="isUSA"/>

      <template v-else>
        <AppCheckbox id="allowPolicy" v-model="allowPolicy" name="allowPolicy" />

        <p>
          <label for="allowPolicy" :class="{ error: errors.allowPolicy }">
            {{ t('allow_policy') }}
          </label>

          <i18n-t keypath="according_terms">
              <LinkPrivacyNotice />
          </i18n-t>
        </p>
      </template>
    </div>

    <ButtonRegular :disabled="!meta.valid" data-id="submit-becomePartnerForm">
      {{ btnText }}
      <SvgArrowForward />
    </ButtonRegular>
  </form>
</template>

<script setup lang="ts">
import { useI18n } from '#i18n';
import * as yup from 'yup';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import { useCommonStore } from '~/stores/common';
import { storeToRefs } from 'pinia';
import { submittedCookieValue } from '~/components/services/FormFillComponent';
import { pushDataLayer } from '~/components/services/Analytics';
import Cookie from '~/components/services/Cookie';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadio from '~/components/redesign/AppRadio.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import ButtonRegular from '~/components/redesign/ButtonRegular.vue';
import PhoneInput from '~/components/redesign/PhoneInput.vue';
import AppCheckbox from '~/components/redesign/AppCheckbox.vue';
import GDPRForUSA from '~/components/redesign/GDPRForUSA.vue';
import LinkPrivacyNotice from '~/components/redesign/LinkPrivacyNotice.vue';

// constants
import REGEX from '~/constants/regex';
import { BECOME_PARTNER } from '~/constants/api-endpoints';

// files
import countriesAlpha3 from '~/data/countries-alpha-3.json';
import countries from '~/data/countries.json';

// types
import type { Countries, Country, CountryWithStates, State } from '~/types/country';

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
  page: {
    type: String,
    required: true,
  },
  btnText: {
    type: String,
    default: 'Submit',
  },
  gtmEvent: {
    type: String,
    default: 'partners',
  },
});

const emit = defineEmits<{
  submitted: [],
  loading: [value: boolean]
}>();

const { t } = useI18n();
type CountriesAlpha3 = Record<string, string>;

const { ipInfo } = storeToRefs(useCommonStore());
const phoneInputRef = ref<null | { isValid(): boolean }>(null);
const RADIO_OPTIONS = ['YES', 'NO'] as const;

yup.addMethod(yup.string, 'phone', function (errorMessage) {
  return this.test(errorMessage, function (value) {
    if (!phoneInputRef.value || !value?.length) return;

    return phoneInputRef.value.isValid() || this.createError({
      path: this.path,
      message: errorMessage,
    });
  });
});

const validationSchema = toTypedSchema(yup.object({
  firstname: yup
    .string()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('firstname') || ''),

  lastname: yup
    .string()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('lastname') || ''),

  email: yup
    .string()
    .matches(REGEX.EMAIL, 'Invalid email address')
    .companyEmail()
    .email()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('email') || ''),

  company: yup
    .string()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('company') || ''),

  title: yup
    .string()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('title') || ''),

  veeam_partner: yup
    .string()
    .oneOf(RADIO_OPTIONS)
    .required(t('This_field_is_required'))
    .default('YES'),

  country: yup
    .object()
    .default({ active: true, label: 'United States', isoCode: 'US' })
    .required(t('This_field_is_required')),

  state: yup
    .object()
    .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
    .when('country', {
      is: (country?: Country) => ['CA', 'US'].includes(country?.isoCode || ''),
      then: schema => schema.required(t('This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),

  phone: yup
    .string()
    .phone(
      'Phone number is invalid. Please add your country code, area code and phone number. ' +
      'Your phone number can contain numbers, spaces and these special characters: ( ) - # +'
    )
    .required('This field is required')
    .default(submittedCookieValue('phone') || ''),

  allowPolicy: yup
    .boolean()
    .when('country', {
      is: (value?: Country) => value?.isoCode === 'US',
      then: schema => schema.notRequired(),
      otherwise: schema => schema
        .oneOf([true], t('This_field_is_required'))
        .required(t('This_field_is_required')),
    }),
}));

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
} = useForm({ validationSchema });

const [firstname] = defineField('firstname');
const [lastname] = defineField('lastname');
const [email] = defineField('email');
const [company] = defineField('company');
const [title] = defineField('title');
const [veeam_partner] = defineField('veeam_partner');
const [country] = defineField('country');
const [state] = defineField('state');
const [phone] = defineField('phone');
const [allowPolicy] = defineField('allowPolicy');

const activeCountries = computed(() => (countries as Countries).filter(country => country.active));
const countryAlpha3 = computed(() => (countriesAlpha3 as CountriesAlpha3)[country.value?.label || '']);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(() => (country.value && ('states' in country.value)));
const activeStates = computed(() => countryHasStates.value
  ? (country.value as CountryWithStates).states.filter(state => state.active)
  : []
);

watch(ipInfo, info => {
  if (!info) return;

  const foundCountry = (countries as Countries).find(country => country.isoCode === info.iso_code);

  if (foundCountry) {
    setFieldValue('country', foundCountry);

    let foundState: State | undefined;

    if ('states' in foundCountry) {
      foundState = foundCountry.states.find(item => item.isoCode === info.state);
    }

    setFieldValue('state', foundState || null);
  }
}, { immediate: true });

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(values => {
  const requestBody = {
    ...omit(values, 'allowPolicy'),

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    page: props.page,
    product: props.product,

    href: window.location.href,

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',
  };

  resetFormFillingStart();
  emit('loading', true);

  const { public: { api_app: baseURL } } = useRuntimeConfig();

  $fetch(BECOME_PARTNER, {
    method: 'POST',
    baseURL,
    body: requestBody,
  }).then(() => {
    const fieldsToUpdate = pick(requestBody, [
      'firstname', 
      'lastname',
       'email',
      'company',
      'title',
      'phone',
    ]);

    const existingCookie = Cookie.get('submitted_params');
    const cookieData = existingCookie ? JSON.parse(existingCookie) : {};

    Cookie.set(
      'submitted_params',
      JSON.stringify({
      ...cookieData,
      ...fieldsToUpdate,
    }),
      86400 * 1000 * 365,
    );

    emit('submitted');
    resetForm();

    Cookie.clearAfterSubmit()
    pushDataLayer(values.email, props.gtmEvent)
  }).catch(error => {
    debug('catch', error);
  }).finally(() => {
    emit('loading', false);
  });
});
</script>

<style scoped lang="scss">
@import "$/functions/token";

@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/common";
@import "$/mixins/media";

@import "$/variables/shadows";

[data-component-name="BecomePartner"] {
  padding: 2rem;
  box-shadow: map-get($shadows, 'Elevation 3');
  background-color: token('surf-cont-primary');

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  @include tablet {
    padding: 1.5rem;
  }

  @include mobile {
    padding: 1.5rem 1rem;
  }

  [data-component-name="AppInput"] {}

  .radio {
    padding: 0 1rem;

    strong {
      @include subtitle-3;

      display: block;
      margin-bottom: 0.5rem;
    }

    ul {
      @include flex-start-start;
      gap: 0.94rem;

      li {
        @include flex-center-start;
        gap: 0.13rem;

        [data-component-name="AppRadio"] {}

        label {
          cursor: pointer;
          @include body-3;
          text-transform: capitalize;
        }
      }
    }

    span.error {
      @include caption;
      color: token('error');
      margin-top: 0.25rem;
      display: block;
    }
  }

  .terms {
    @include flex-start-start;
    gap: 0.25rem;

    [data-component-name="AppCheckbox"] {}

    p {
      @include caption;

      label {
        display: block;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &.error {
          @include caption;
          color: token('error');
        }
      }

      a {
        color: token('link');
        text-decoration: underline;
      }
    }
  }

  [data-component-name="ButtonRegular"] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
