<template>
  <form
    data-component-name="ContactUs"
    autocomplete="off"
    @input.passive="setFormFillingStart"
    @submit="submitHandler"
    data-id="contactUs"
  >
    <AppInput
      v-model.trim="fullname"
      type="text"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      name="fullname"
      required
    />

    <AppInput
      v-model="email"
      type="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      name="email"
      required
    />

    <AppInput
      v-model="company"
      type="text"
      :label="$t('Company')"
      :error="errors.company"
      name="company"
      required
    />

    <div class="radio">
      <strong v-html="$t('are_you_of_partner')" />

      <ul>
        <li
          v-for="option of RADIO_OPTIONS"
          :key="option"
        >
          <AppRadio
            v-model="of_partner"
            :value="option"
            :id="`${option}-${id}`"
            :name="`role-${option}`"
          />

          <label :for="`${option}-${id}`">{{ option.toLowerCase() }}</label>
        </li>
      </ul>

      <span v-if="errors.of_partner" class="error" name="error">
        {{ errors.of_partner }}
      </span>
    </div>

    <AppDropdown
      v-model="country"
      :options="activeCountries"
      option-attribute="label"
      :label="$t('Country')"
      :placeholder="$t('Select_your_country')"
      name="country"
      required
      searchable
      :searchable-placeholder="$t('Search_by_country_name')"
      :search-attributes="['label', 'isoCode']"
      :error="errors.country"
    />

    <AppDropdown
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      option-attribute="label"
      :label="$t('State')"
      name="state"
      :placeholder="$t('Select_your_state')"
      required
      searchable
      :searchable-placeholder="$t('Search_by_state_name')"
      :search-attributes="['label', 'isoCode']"
      :error="errors.state"
    />

    <AppTextarea
      v-model="comment"
      :placeholder="$t('please_briefly_describe_request')"
      :error="errors.comment"
      name="comment"
    />

    <div class="terms">
      <GDPRForUSA v-if="isUSA"/>

      <template v-else>
        <AppCheckbox :id="`allowPolicy-${id}`" v-model="allowPolicy"  name="allowPolicy" />

        <p>
          <label :for="`allowPolicy-${id}`" :class="{ error: errors.allowPolicy }">
            {{ $t('allow_policy') }}
          </label>

          <i18n-t keypath="according_terms">
            <LinkPrivacyNotice />
          </i18n-t>
        </p>
      </template>
    </div>

    <ButtonRegular :disabled="!meta.valid" data-id="submit-contactUs">
      {{ btnText }}
      <SvgArrowForward />
    </ButtonRegular>
  </form>
</template>

<script setup lang="ts">
import * as yup from 'yup';
import pick from 'lodash.pick';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { useI18n, useLocalePath } from '#i18n';
import { useCommonStore } from '~/stores/common';
import { toTypedSchema } from '@vee-validate/yup';
import { submittedCookieValue } from '~/components/services/FormFillComponent';

// json
import countries from '~/data/countries.json';
import countriesAlpha3 from '~/data/countries-alpha-3.json';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadio from '~/components/redesign/AppRadio.vue';
import AppCheckbox from '~/components/redesign/AppCheckbox.vue';
import AppTextarea from '~/components/redesign/AppTextarea.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import ButtonRegular from '~/components/redesign/ButtonRegular.vue';
import GDPRForUSA from '~/components/redesign/GDPRForUSA.vue';
import LinkPrivacyNotice from '~/components/redesign/LinkPrivacyNotice.vue';

// constants
import REGEX from '~/constants/regex';
import { CONNECT_CHANNEL_MANAGER } from '~/constants/api-endpoints';

// utils
import getSubmittedFullName from '~/utils/getSubmittedFullName';

// types
import type {
  State,
  Countries,
  Country,
  CountryWithStates,
  CountriesAlpha3,
} from '~/types/country';
import omit from "lodash.omit";
import Cookie from '~/components/services/Cookie';
import { pushDataLayer } from "~/components/services/Analytics";

const id = useId();

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
  page: {
    type: String,
    required: true,
  },
  btnText: {
    type: String,
    default: 'Submit',
  },
  gtmEvent: {
    type: String,
    default: 'partners',
  },
});

const emit = defineEmits<{
  submitted: [],
  loading: [value: boolean]
}>();

const RADIO_OPTIONS = ['YES', 'NO'] as const;

const { t } = useI18n();
const { ipInfo } = storeToRefs(useCommonStore());

const validationSchema = toTypedSchema(yup.object({
  fullname: yup
    .string()
    .required(t('This_field_is_required'))
    .default(getSubmittedFullName()),

  email: yup
    .string()
    .matches(REGEX.EMAIL, 'Invalid email address')
    .companyEmail()
    .email()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('email') || ''),

  company: yup
    .string()
    .required(t('This_field_is_required'))
    .default(submittedCookieValue('company') || ''),

  of_partner: yup
    .string()
    .oneOf(RADIO_OPTIONS)
    .required(t('This_field_is_required'))
    .default(RADIO_OPTIONS[0]),

  country: yup
    .object()
    .default({ active: true, label: 'United States', isoCode: 'US' })
    .required(t('This_field_is_required')),

  state: yup
    .object()
    .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
    .when('country', {
      is: (country?: Country) => ['CA', 'US'].includes(country?.isoCode || ''),
      then: schema => schema.required(t('This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),

  comment: yup
    .string()
    .notRequired(),

  allowPolicy: yup
    .boolean()
    .when('country', {
      is: (value?: Country) => value?.isoCode === 'US',
      then: schema => schema.notRequired(),
      otherwise: schema => schema
        .oneOf([true], t('This_field_is_required'))
        .required(t('This_field_is_required')),
    }),
}));

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
} = useForm({ validationSchema });

const [fullname] = defineField('fullname');
const [email] = defineField('email');
const [company] = defineField('company');
const [of_partner] = defineField('of_partner');
const [country] = defineField('country');
const [state] = defineField('state');
const [comment] = defineField('comment');
const [allowPolicy] = defineField('allowPolicy');

const activeCountries = computed(() => (countries as Countries).filter(country => country.active));
const countryAlpha3 = computed(() => (countriesAlpha3 as CountriesAlpha3)[country.value?.label || '']);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(() => (country.value && ('states' in country.value)));
const activeStates = computed(() => countryHasStates.value
  ? (country.value as CountryWithStates).states.filter(state => state.active)
  : []
);

watch(ipInfo, info => {
  if (!info) return;

  const foundCountry = (countries as Countries).find(country => country.isoCode === info.iso_code);

  if (foundCountry) {
    setFieldValue('country', foundCountry);

    let foundState: State | undefined;

    if ('states' in foundCountry) {
      foundState = foundCountry.states.find(item => item.isoCode === info.state);
    }

    setFieldValue('state', foundState || null);
  }
}, { immediate: true });

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(values => {
  const [firstname, lastname] = values.fullname.split(' ');

  const requestBody = {
    ...omit(values, 'allowPolicy', 'fullname'),

    firstname,
    lastname: lastname || firstname,

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    page: props.page,
    product: props.product,

    href: window.location.href,

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',
  };

  resetFormFillingStart();
  emit('loading', true);

  const { public: { api_app: baseURL } } = useRuntimeConfig();

  $fetch(CONNECT_CHANNEL_MANAGER, {
    method: 'POST',
    baseURL,
    body: requestBody,
  }).then(() => {
    const fieldsToUpdate = pick(requestBody, ['firstname', 'lastname', 'email', 'company']);

    const existingCookie = Cookie.get('submitted_params');
    const cookieData = existingCookie ? JSON.parse(existingCookie) : {};

    Cookie.set(
      'submitted_params',
      JSON.stringify({
      ...cookieData,
      ...fieldsToUpdate,
    }),
      86400 * 1000 * 365,
    );

    emit('submitted');
    resetForm();

    Cookie.clearAfterSubmit()
    pushDataLayer(values.email, props.gtmEvent)
  }).catch(error => {
    debug('catch', error);
  }).finally(() => {
    emit('loading', false);
  });
});
</script>

<style scoped lang="scss">
@import "$/functions/token";

@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/common";
@import "$/mixins/media";

@import "$/variables/shadows";

[data-component-name="ContactUs"] {
  padding-top: 0.5rem;

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  @include tablet {
    gap: 1rem;
  }

  [data-component-name="AppInput"] {}

  .radio {
    padding: 0 1rem;

    strong {
      @include subtitle-3;

      display: block;
      margin-bottom: 0.5rem;
    }

    ul {
      @include flex-start-start;
      gap: 0.94rem;

      li {
        @include flex-center-start;
        gap: 0.13rem;

        [data-component-name="AppRadio"] {}

        label {
          cursor: pointer;
          @include body-3;
          text-transform: capitalize;
        }
      }
    }

    span.error {
      @include caption;
      color: token('error');
      margin-top: 0.25rem;
      display: block;
    }
  }

  .terms {
    @include flex-start-start;
    gap: 0.25rem;

    [data-component-name="AppCheckbox"] {}

    p {
      @include caption;

      label {
        display: block;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &.error {
          @include caption;
          color: token('error');
        }
      }

      a {
        color: token('link');
        text-decoration: underline;
      }
    }
  }

  [data-component-name="ButtonRegular"] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
